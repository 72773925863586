<template>
  <vca-field :label="$t('email.event.label')">
    <h3>{{ $t("email.event.event.label") }}</h3>
    <vca-dropdown
      v-model="event"
      :options="eventsList"
      label=""
      :placeholder="$t('email.event.placeholder')"
    ></vca-dropdown>
    <div v-if="event_id">
      <h3>{{ $t("email.event.group.label") }}</h3>
      <vca-row>
        <vca-checkbox
          v-for="entity in participationsTypes"
          v-model="value.recipient_group.state"
          :key="entity"
          :id="entity"
          >{{ $t("email.event.group." + entity) }} ({{
            participationsCount(entity)
          }})</vca-checkbox
        >
        <div
          v-if="participationsTypes.length == 0"
          class="vca-center highlight"
        >
          {{ $t("email.event.group.empty") }}
        </div>
      </vca-row>
    </div>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmailEventGroup",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    participationsCount(type) {
      return this.participations.filter((item) => {
        return item.status == type;
      }).length;
    },
  },
  computed: {
    ...mapGetters({
      events: "events/eventList",
      publishedStates: "events/publishedstates",
      participations: "events/participations/list",
    }),
    eventsList() {
      return this.events.filter((item) => {
        return this.publishedStates.includes(item.event_state.state);
      });
    },
    participationsTypes() {
      return this.participations
        ? [...new Set(this.participations.map((item) => item.status))].filter(
            (item) => {
              return ["confirmed", "requested", "rejected"].includes(item);
            }
          )
        : [];
    },
    event_id() {
      return this.event.length > 0 ? this.event[0].value : null;
    },
    event: {
      get() {
        return [{ value: this.value.recipient_group.event_id }];
      },
      set(value) {
        let eventID = value.length ? value[0].value : null;

        let data = { event_id: eventID };
        this.$store
          .dispatch({ type: "events/participations/queryList", data: data })
          .then((response) => {
            this.$store.commit(
              "events/participations/list",
              !response || response.length == 0 ? [] : response
            );
          });

        this.value.recipient_group.event_id = eventID;
      },
    },
  },
};
</script>
