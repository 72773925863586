<template>
  <vca-field :label="$t('email.mailbox.header')">
    <div>{{ select[0].title }}: {{ select[0].subtitle }}</div>
    <br />
    <div v-if="mailboxSelect">
      <p v-html="$t('email.mailbox.select.description')"></p>
      <vca-column>
        <vca-dropdown
          v-model="select"
          :options="mailboxList"
          label=""
          :placeholder="$t('profile.edit.crew.select.placeholder')"
        ></vca-dropdown>
      </vca-column>
    </div>
  </vca-field>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MailboxSelection",
  computed: {
    select: {
      set(value) {
        this.$store.commit("emails/mailbox/message/selectMailbox", value);
      },
      get() {
        return this.$store.state.emails.mailbox.message.selectMailbox.filter(
          (el) => {
            el.title = this.$t(el.title);
            el.subtitle = this.$t(el.subtitle);
            el.label = this.$t(el.label);
            return true;
          }
        );
      },
    },
    mailboxList: {
      get() {
        return this.$store.state.emails.mailboxList.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    ...mapGetters({
      mailboxSelect: "emails/mailbox/message/mailboxSelect",
    }),
  },
};
</script>
