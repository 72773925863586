<template>
  <div>
    <div v-if="preview || sendable">
      <div v-if="!sending_mail">
        <div ref="email_content" style="width: 100%">
          <div class="ql-container">
            <div class="vca-em-head">
              <div>
                {{ $t("email.preview.from") }}: {{ getSender }}<br />
                {{ $t("email.preview.to") }}: {{ getReceiver }}<br />
                {{ sent_date }}
              </div>
              <div class="headline">
                {{ $t("email.preview.news") }}
              </div>
              <div class="label desktop-mail">
                {{ $t("email.preview.vca") }}
              </div>
            </div>
            <div class="ql-editor" v-html="htmlContent"></div>
            <div id="END_CONTENT"></div>
            <div class="vca-em-info">
              <p>
                <i18n path="email.preview.browser_view.text">
                  <a
                    :href="getBrowserUrl"
                    :title="$t('email.preview.browser_view.title')"
                    target="_blank"
                    >{{ $t("email.preview.browser_view.label") }}</a
                  >
                </i18n>
              </p>
              <p>
                <span>{{ $t("email.preview.unsubscribe.reason") }}</span
                ><br />
                <i18n path="email.preview.unsubscribe.text">
                  <a
                    :href="getUnsubscribeUrl"
                    :title="$t('email.preview.unsubscribe.title')"
                    target="_blank"
                    >{{ $t("email.preview.unsubscribe.label") }}</a
                  > </i18n
                ><br />
                <span>{{ $t("email.preview.unsubscribe.alternative") }}</span>
              </p>
            </div>
            <div class="vca-em-foot">
              <a
                href="https://www.vivaconagua.org"
                title="Viva con Agua Webseite"
                target="_blank"
                >{{ $t("email.preview.organisation") }}</a
              >
            </div>
          </div>
        </div>
        <button v-if="sendable" class="vca-button" @click.prevent="send()">
          {{ $t("button.send") }}
        </button>
        <div v-if="deleteable" class="vca-center">
          <vca-cancel-button
            @click="remove()"
            :placeholder="$t('button.delete')"
          />
        </div>
      </div>
      <div v-else>
        <vca-card class="text-center shadowed">
          <h1>{{ $t("email.preview.sending") }}</h1>
        </vca-card>
      </div>
    </div>
    <div v-else>
      <div class="ql-editor" v-html="htmlContent"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import email_style from "!raw-loader!@/assets/styles/email.css";
export default {
  name: "EmailPreview",
  data() {
    return {
      sending_mail: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    event: {
      type: Object,
      default: null,
    },
    preview: {
      type: Boolean,
      default: true,
    },
    sendable: {
      type: Boolean,
      default: true,
    },
    deleteable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getBrowserUrl() {
      return process.env.VUE_APP_URL + "/account/emails";
    },
    getUnsubscribeUrl() {
      return process.env.VUE_APP_URL + "/account/settings";
    },
    sent_date() {
      return this.preview
        ? new Date(Date.now()).toLocaleDateString()
        : new Date(this.value.modified.updated * 1000).toLocaleDateString();
    },
    getSender() {
      return this.value.from
        ? this.value.from
        : this.$t("email.preview.missing_sender");
    },
    getReceiver() {
      let receiver = " - ";

      if (this.value.recipient_group.crew_id) {
        const crew = this.getCrew(this.value.recipient_group.crew_id);
        if (crew) {
          receiver = crew.name;
        }
      } else if (this.value.recipient_group.event_id) {
        if (this.event) {
          return this.event.name;
        }
        const event = this.getEvent(this.value.recipient_group.event_id);
        if (event) {
          receiver = event.name;
        }
      }

      return this.value.recipient_group.type
        ? this.$t(
            "email.preview.recipient_group." + this.value.recipient_group.type,
            {
              0: receiver,
            }
          )
        : this.$t("email.preview.missing_receivers");
    },
    htmlContent() {
      if (this.value.message) {
        return this.fromBinary(this.value.message);
      } else {
        return "";
      }
    },
    ...mapGetters({
      getCrew: "crews/getCrew",
      getEvent: "events/getEvent",
    }),
  },
  methods: {
    remove() {
      this.$emit("delete");
    },
    send() {
      var html = this.$refs.email_content.innerHTML;
      html =
        '<!DOCTYPE html><html lang="' +
        localStorage.language +
        '"><head><style type="text/css" rel="stylesheet">' +
        email_style +
        '</style><meta charset="utf-8"></head><body>' +
        html +
        "</body></html>";
      this.sending_mail = true;
      this.value.message = this.toBinary(
        html.replace(/(\r\n|\n|\r)/gm, "").trim()
      );
      this.$emit("send");
    },
  },
};
</script>
<style type="text/css" src="@/assets/styles/email.css"></style>
