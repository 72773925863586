<template>
  <div>
    <vca-field :label="$t('email.tips.header')">
      <h3>{{ $t("email.tips.styles.header") }}</h3>
      <div>{{ $t("email.tips.styles.text") }}</div>

      <h3>{{ $t("email.tips.links.header") }}</h3>
      <div>{{ $t("email.tips.links.text") }}</div>

      <h3>{{ $t("email.tips.copy.header") }}</h3>
      <div>{{ $t("email.tips.copy.text") }}</div>

      <h3>{{ $t("email.tips.preview.header") }}</h3>
      <div>{{ $t("email.tips.preview.text") }}</div>
    </vca-field>
  </div>
</template>

<script>
export default {
  name: "EmailTips",
};
</script>
