<template>
  <vca-field :label="$t('email.membership.label')">
    <vca-row>
      <div>
        <h4>{{ $t("email.membership.active.label") }}</h4>
        <vca-checkbox v-model="filter_all_active" id="all">
          <span class="bold" v-if="!filter_all_active">{{
            $t("email.membership.active.all")
          }}</span>
          <span class="bold" v-else>{{
            $t("email.membership.active.none")
          }}</span>
          <div class="inline-infobox">
            <vca-info-box>{{
              $t("email.membership.active.info")
            }}</vca-info-box>
          </div>
        </vca-checkbox>
        <vca-checkbox
          v-model="value.recipient_group.active_state"
          id="confirmed"
          >{{ $t("email.membership.active.confirmed") }}</vca-checkbox
        >
        <vca-checkbox
          v-model="value.recipient_group.active_state"
          id="requested"
          >{{ $t("email.membership.active.requested") }}</vca-checkbox
        >
        <vca-checkbox
          v-model="value.recipient_group.active_state"
          id="rejected"
          >{{ $t("email.membership.active.rejected") }}</vca-checkbox
        >
        <vca-checkbox
          v-model="value.recipient_group.active_state"
          id="inactive"
          >{{ $t("email.membership.active.inactive") }}</vca-checkbox
        >
        <vca-checkbox
          v-model="value.recipient_group.active_state"
          id="not_requested"
          >{{ $t("email.membership.active.not_requested") }}</vca-checkbox
        >
      </div>
      <div>
        <h4>{{ $t("email.membership.nvm.label") }}</h4>
        <vca-checkbox v-model="filter_all_nvm" id="all">
          <span class="bold" v-if="!filter_all_nvm">{{
            $t("email.membership.nvm.all")
          }}</span>
          <span class="bold" v-else>{{ $t("email.membership.nvm.none") }}</span>
          <div class="inline-infobox">
            <vca-info-box>{{ $t("email.membership.nvm.info") }}</vca-info-box>
          </div>
        </vca-checkbox>
        <vca-checkbox
          v-model="value.recipient_group.nvm_state"
          id="confirmed"
          >{{ $t("email.membership.nvm.confirmed") }}</vca-checkbox
        >
        <vca-checkbox v-model="value.recipient_group.nvm_state" id="expired">{{
          $t("email.membership.nvm.expired")
        }}</vca-checkbox>
        <vca-checkbox v-model="value.recipient_group.nvm_state" id="inactive">{{
          $t("email.membership.nvm.inactive")
        }}</vca-checkbox>
        <vca-checkbox
          v-model="value.recipient_group.nvm_state"
          id="not_requested"
          >{{ $t("email.membership.nvm.not_requested") }}</vca-checkbox
        >
      </div>
    </vca-row>
    <vca-row>
      <h3 class="short">{{ $t("email.force.label") }}</h3>
      <div class="long">
        <vca-card>
          <vca-column>
            <vca-checkbox v-model="value.recipient_group.ignore_newsletter" />
            <div class="highlight">
              {{ $t("email.force.info") }}
            </div>
          </vca-column>
        </vca-card>
      </div>
    </vca-row>
  </vca-field>
</template>

<script>
export default {
  name: "EmailCrewMembership",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      filter_all_active: false,
      filter_all_nvm: false,
    };
  },
  watch: {
    filter_all_active: function (nVal) {
      this.value.recipient_group.active_state = nVal
        ? ["confirmed", "requested", "rejected", "inactive", "not_requested"]
        : [];
    },
    filter_all_nvm: function (nVal) {
      this.value.recipient_group.nvm_state = nVal
        ? ["confirmed", "expired", "inactive", "not_requested"]
        : [];
    },
  },
};
</script>
