var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vca-column',[_c('vca-card',{staticClass:"shadowed"},[_c('Receivers',{ref:"receivers",model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}})],1),(_vm.getReceiverGroup)?_c('vca-card',{staticClass:"shadowed"},[_c('MailboxSelection')],1):_vm._e(),(_vm.getReceiverGroup == 'employee_crew' && _vm.hasSystemPermission)?_c('vca-card',{staticClass:"shadowed"},[_c('CrewSelection')],1):_vm._e(),(_vm.getReceiverGroup)?_c('div',[(_vm.getReceiverGroup == 'event')?_c('vca-card',{staticClass:"shadowed"},[_c('Group',{ref:"group",model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}})],1):(
          _vm.getReceiverGroup == 'crew' || _vm.getReceiverGroup == 'employee_crew'
        )?_c('vca-card',{staticClass:"shadowed"},[_c('Membership',{model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}})],1):_vm._e(),(
          _vm.getReceiverGroup == 'crew' ||
          (_vm.getReceiverGroup == 'employee_crew' &&
            _vm.create.recipient_group.crew_id) ||
          (_vm.getReceiverGroup == 'event' && _vm.create.recipient_group.event_id)
        )?_c('vca-card',{staticClass:"shadowed"},[_c('Email',{ref:"email",model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}})],1):_vm._e(),_c('vca-card',{staticClass:"shadowed"},[_c('Tips')],1)],1):_vm._e()],1),_c('button',{staticClass:"vca-button",attrs:{"disabled":_vm.isInvalid},on:{"click":function($event){return _vm.add()}}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")]),_c('div',{staticClass:"vca-center"},[_c('vca-column',{staticClass:"vca-center"},[_c('button',{staticClass:"vca-button-small vca-center",attrs:{"disabled":_vm.isInvalid},on:{"click":function($event){_vm.preview = true}}},[_vm._v(" "+_vm._s(_vm.$t("button.preview"))+" & "+_vm._s(_vm.$t("button.send"))+" ")])])],1),(_vm.preview)?_c('vca-popup',{attrs:{"show":_vm.preview,"title":_vm.$t('email.preview.title', { 0: _vm.create.subject })},on:{"close":function($event){_vm.preview = false}}},[_c('EmailPreview',{on:{"send":_vm.send},model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }