<template>
  <vca-card>
    <h2>{{ $t("email.new.header") }}</h2>
    <EmailAdd />
  </vca-card>
</template>

<script>
import EmailAdd from "@/components/emails/EmailAdd";
export default {
  name: "EmailsAdd",
  components: { EmailAdd },
};
</script>
