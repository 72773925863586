<template>
  <vca-field :label="$t('email.receivers.header')">
    <h3>{{ $t("email.receivers.label") }}</h3>
    <vca-dropdown
      v-model="recipient_group_type"
      ref="receivers"
      :options="receivers"
      label=""
      :rules="$v.value.recipient_group.type"
      :errorMsg="$t('email.receivers.errorMsg')"
      :placeholder="$t('email.receivers.placeholder')"
    />
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmailsReceivers",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    recipient_group_type: {
      get() {
        return this.value ? [{ value: this.value.recipient_group.type }] : [];
      },
      set(value) {
        let newValue = this.value;
        newValue.recipient_group.type = value.length ? value[0].value : null;
        if (newValue.recipient_group.type == "crew") {
          newValue.recipient_group.crew_id = this.user.crew.crew_id;
          this.$store.commit(
            "emails/mailbox/message/selectMailbox",
            this.mailboxList.filter((el) => {
              if (el.type !== "crew") {
                return false;
              }
              return true;
            })
          );
        }
        if (newValue.recipient_group.type == "employee_crew") {
          this.$store.commit(
            "emails/mailbox/message/selectMailbox",
            this.mailboxList.filter((el) => {
              newValue.recipient_group.crew_id = "";
              if (el.type !== "employee") {
                return false;
              }
              return true;
            })
          );
        }
        if (newValue.recipient_group.type == "event") {
          var tempList = JSON.parse(JSON.stringify(this.mailboxList));
          this.$store.commit(
            "emails/mailbox/message/selectMailbox",
            tempList.splice(0, 1)
          );
        }
        this.value = newValue;
      },
    },
    receivers: {
      get() {
        return this.$store.state.emails.receivers.filter((el) => {
          if (
            this.hasSystemPermission() &&
            !this.user.crew.id &&
            el.value == "crew"
          ) {
            return false;
          }
          if (!this.hasSystemPermission() && el.value == "employee_crew") {
            return false;
          }
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    ...mapGetters({
      mailboxList: "emails/mailboxList",
      user: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
      validation: "emails/mailbox/message/validations",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.receivers.validate();
    },
  },
};
</script>
