<template>
  <div>
    <div
      class="vca-input"
      :class="{ error: hasError === true, valid: hasError === false }"
    >
      <quill-editor class="vca-quill" v-model="htmlContent" />
      <span class="errorMsg" v-if="hasError">{{
        $t("email.message.message.errorMsg")
      }}</span>
      <span v-else></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailEditor",
  data() {
    return {
      hasError: null,
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.cleanMessage();
  },
  computed: {
    htmlContent: {
      set(value) {
        this.value.message = this.toBinary(value);
      },
      get() {
        if (this.value.message) {
          return this.fromBinary(this.value.message);
        } else {
          return "";
        }
      },
    },
  },
  methods: {
    cleanMessage() {
      if (this.value.message) {
        var message = this.fromBinary(this.value.message);

        // If we tried to send the email and already added the whole doctype shizzle to it, we re-parse the content to the initial message content
        var editor_start = message.indexOf('<div class="ql-editor">');
        var editor_end = message.indexOf('<div id="END_CONTENT">');
        if (editor_start != -1 && editor_end != -1) {
          var body_message = message.substring(
            editor_start + '<div class="ql-editor">'.length,
            editor_end - "</div>".length
          );
          this.value.message = this.toBinary(body_message);
        }
      }
    },
    validate() {
      this.hasError = false;
      if (this.rules !== null && this.rules.$invalid) {
        this.hasError = true;
      }
    },
  },
};
</script>
<style lang="scss">
.vca-quill {
  height: 100%;
}
</style>
