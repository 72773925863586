<template>
  <div>
    <vca-column>
      <vca-card class="shadowed">
        <Receivers v-model="create" ref="receivers" />
      </vca-card>
      <vca-card v-if="getReceiverGroup" class="shadowed">
        <MailboxSelection />
      </vca-card>
      <vca-card
        v-if="getReceiverGroup == 'employee_crew' && hasSystemPermission"
        class="shadowed"
      >
        <CrewSelection />
      </vca-card>
      <div v-if="getReceiverGroup">
        <vca-card v-if="getReceiverGroup == 'event'" class="shadowed">
          <Group v-model="create" ref="group" />
        </vca-card>
        <vca-card
          v-else-if="
            getReceiverGroup == 'crew' || getReceiverGroup == 'employee_crew'
          "
          class="shadowed"
        >
          <Membership v-model="create" />
        </vca-card>
        <vca-card
          v-if="
            getReceiverGroup == 'crew' ||
            (getReceiverGroup == 'employee_crew' &&
              create.recipient_group.crew_id) ||
            (getReceiverGroup == 'event' && create.recipient_group.event_id)
          "
          class="shadowed"
        >
          <Email v-model="create" ref="email" />
        </vca-card>
        <vca-card class="shadowed">
          <Tips />
        </vca-card>
      </div>
    </vca-column>

    <button class="vca-button" @click="add()" :disabled="isInvalid">
      {{ $t("button.save") }}
    </button>
    <div class="vca-center">
      <vca-column class="vca-center">
        <button
          class="vca-button-small vca-center"
          :disabled="isInvalid"
          @click="preview = true"
        >
          {{ $t("button.preview") }} & {{ $t("button.send") }}
        </button>
      </vca-column>
    </div>

    <vca-popup
      v-if="preview"
      :show="preview"
      :title="$t('email.preview.title', { 0: create.subject })"
      @close="preview = false"
    >
      <EmailPreview v-model="create" @send="send" />
    </vca-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Receivers from "@/components/emails/form/Receivers";
import Email from "@/components/emails/form/Email";
import Tips from "@/components/emails/form/Tips";
import Group from "@/components/emails/form/event/Group";
import Membership from "@/components/emails/form/crew/Membership";
import EmailPreview from "@/components/emails/EmailPreview";
import CrewSelection from "./form/crew/CrewSelection.vue";
import MailboxSelection from "./form/MailboxSelection.vue";
export default {
  name: "EmailAdd",
  components: {
    Receivers,
    Email,
    Tips,
    EmailPreview,
    Membership,
    Group,
    CrewSelection,
    MailboxSelection,
  },
  data() {
    return {
      preview: false,
    };
  },
  created() {
    this.$store.commit("emails/mailbox/message/user_id", this.user.id);
  },
  methods: {
    add() {
      if (this.isInvalid) {
        this.$refs.receivers.validate();
        if (this.getReceiverGroup == "event") {
          this.$refs.group.validate();
        }
        this.$refs.email.validate();
      } else {
        this.$store.dispatch("emails/mailbox/message/create").then(() => {
          this.$store.dispatch("emails/mailbox/get").then(() => {
            this.$store.commit("emails/mailbox/message/reset_create");
            this.$router.push({ path: "/emails/drafts" });
          });
        });
      }
    },
    send() {
      if (this.isInvalid) {
        this.preview = false;
        this.$refs.receivers.validate();
        if (this.getReceiverGroup == "event") {
          this.$refs.group.validate();
        }
        this.$refs.email.validate();
      } else {
        this.$store
          .dispatch("emails/mailbox/message/create")
          .then((response) => {
            this.$store.commit("emails/mailbox/message/current", response);
            this.$store.dispatch("emails/mailbox/message/send").then(() => {
              this.$store.dispatch("emails/mailbox/get").then(() => {
                this.$store.commit("emails/mailbox/message/reset_create");
                this.$router.push({
                  path: "/emails/outbox",
                });
              });
            });
          });
      }
    },
  },
  computed: {
    create: {
      set(value) {
        this.$store.commit("emails/mailbox/message/create", value);
      },
      get() {
        return this.$store.state.emails.mailbox.message.create;
      },
    },
    isInvalid() {
      return this.$v.create.$invalid;
    },
    getReceiverGroup() {
      return this.create.recipient_group.type;
    },
    ...mapGetters({
      user: "user/current",
      validation: "emails/mailbox/message/validations",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
  validations() {
    return this.validation;
  },
};
</script>
