<template>
  <vca-field :label="$t('email.crew.header')">
    <p v-html="$t('email.crew.description')"></p>
    <vca-column>
      <vca-dropdown
        v-model="select"
        :options="crewList"
        label=""
        :placeholder="$t('profile.edit.crew.select.placeholder')"
      ></vca-dropdown>
    </vca-column>
  </vca-field>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewSelection",
  computed: {
    select: {
      set(value) {
        this.$store.commit("emails/mailbox/message/selectCrew", value);
      },
      get() {
        return this.$store.state.emails.mailbox.message.selectCrew;
      },
    },
    ...mapGetters({
      crewList: "crews/dropdown",
    }),
  },
  created() {
    this.$store.dispatch({ type: "crews/public" }).then().catch();
  },
};
</script>
