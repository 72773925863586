<template>
  <div>
    <vca-dropdown
      v-model="sender"
      :options="senderList"
      ref="from"
      label=""
      :rules="$v.value.from"
      :errorMsg="$t('email.sender.errorMsg')"
      :placeholder="$t('email.sender.placeholder')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmailSender",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (!this.hasSystemPermission()) {
      this.value.from = this.user.crew.email;
    }
  },
  computed: {
    sender: {
      get() {
        return [{ value: this.value.from }];
      },
      set(value) {
        let newValue = this.value;
        newValue.from = value.length ? value[0].value : null;
        this.value = newValue;
      },
    },
    senderList() {
      if (this.mailboxType === "crew") {
        let sender = [
          {
            title: this.$t("email.sender.crew.title"),
            email_title: this.$t("email.sender.crew.email_title", {
              0: this.user.crew.name,
            }),
            subtitle: this.$t("email.sender.crew.subtitle", {
              0: this.user.crew.name,
              1: this.user.crew.email,
            }),
            label: this.$t("email.sender.crew.label"),
            value: this.user.crew.email,
          },
        ];
        return sender;
      }
      if (this.mailboxType === "employee") {
        let sender = [
          {
            title: this.$t("email.sender.self.title"),
            email_title: this.user.full_name,
            subtitle: this.$t("email.sender.self.subtitle", {
              0: this.user.full_name,
              1: this.user.email,
            }),
            label: this.$t("email.sender.self.label"),
            value: this.user.email,
          },
          {
            title: this.$t("email.sender.anon.title"),
            email_title: this.$t("email.sender.anon.email_title"),
            subtitle: this.$t("email.sender.anon.subtitle"),
            label: this.$t("email.sender.anon.label"),
            value: "no-reply@vivaconagua.org",
          },
        ];

        return sender;
      }
      return [];
    },
    ...mapGetters({
      user: "user/current",
      validation: "emails/mailbox/message/validations",
      hasSystemPermission: "user/roles/hasSystemPermission",
      mailboxType: "emails/mailbox/message/mailboxType",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.from.validate();
    },
  },
};
</script>
